import React from "react"
import { withRouter } from "react-router-dom"
import { Nav, NavItem, NavLink } from "reactstrap"
// graphql
// helpers
import { Offline } from "react-detect-offline"
// utilities
// state
// styles
import "./styles.scss"

const ProfileLayout = ({ children, history }) => {
  const changeRoute = (e, route) => {
    e.preventDefault()
    history.push(route)
  }
  const isActiveRoute = (route) => {
    return history.location.pathname === route ? true : false
  }
  return (
    <div className="propiedades-search-page">
      <div className="container mt-15">
        <div className="row">
          <Nav tabs>
            <NavItem>
              <NavLink
                href="#"
                onClick={(e) => changeRoute(e, "/mi-cuenta")}
                active={isActiveRoute("/mi-cuenta")}>
                Mi cuenta
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                onClick={(e) => changeRoute(e, "/metodos-de-pago")}
                active={isActiveRoute("/metodos-de-pago")}>
                Métodos de pago
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <OfflineMessage />
        {children}
      </div>
    </div>
  )
}

const OfflineMessage = () => {
  return (
    <Offline>
      <div className="container">
        <div className="alert alert-warning" role="alert">
          Tu conexión a internet es inestable, tratoendirecto no funcionará como es esperado, revisa
          tu conexión a internet.
        </div>
      </div>
    </Offline>
  )
}
export default withRouter(ProfileLayout)
