import React from "react"
import { useHistory } from "react-router-dom"
// helpers
import moment from "moment"
import "./styles.scss"
const Factura = ({ props: { amount_due, current_period_end, paid, id, is_on_stripe } }) => {
  let history = useHistory()
  return (
    <>
      <div className="t-body product border-bottom" onClick={() => history.push(`/facturas/${id}`)}>
        <div className="container">
          <div className="row">
            <div className="col-lg-2">
              <p>CPANEL</p>
            </div>
            <div className="col-lg-2">
              <p>
                {paid === false && "Pago no realizado"}
                {paid === true && "Pagado"}
              </p>
            </div>
            <div className="col-lg-2">
              {/* <p>${(amount_due / 100).toFixed(2)}</p> */}
              <p>{amount_due}</p>
            </div>
            <div className="col-lg-2">
              <p>
                {is_on_stripe
                  ? moment.unix(current_period_end).format("DD/MM/YYYY")
                  : current_period_end}
              </p>
            </div>
            <div className="col-lg-2">
              <p>
                {is_on_stripe
                  ? moment.unix(current_period_end).format("DD/MM/YYYY")
                  : current_period_end}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Factura
