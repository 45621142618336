import React, { useState, useEffect } from "react"
// amplify
import { Auth } from "aws-amplify"
// utilities
import { FormGroup, Input, Label } from "reactstrap"
import { withRouter } from "react-router-dom"
import queryString from "query-string"
import "../authstyles.scss"

const ConfirmationCode = ({ history, location }) => {
  const [code, setCode] = useState("")
  const [email, setEmail] = useState("")
  const [alert, setAlert] = useState(null)

  const handleOnChange = (e, func) => {
    const value = e.currentTarget.value
    func(value)
  }

  const confirmAccount = () => {
    setAlert("Creando usuario")
    Auth.confirmSignUp(email, code)
      .then((data) => {
        history.push("/login")
      })
      .catch((error) => {
        let mensajeError = ""
        switch (error.message) {
          case "Username cannot be empty":
            mensajeError = "Favor de escribir su nombre de usuario"
            break
          case "Custom auth lambda trigger is not configured for the user pool":
            mensajeError = "Favor de escribir su contraseña"
            break
          case "Incorrect username or password.":
            mensajeError = "Nombre de usuario o contraseña incorrectos"
            break
          default:
            mensajeError = "Favor de verificar su información e intentar de nuevo"
        }
        setAlert(mensajeError)
      })
  }

  useEffect(() => {
    // recibimos el correo por parámetro de la liga pasada y se lo ponemos al estado para que no tengan que reescribir su propio correo
    let params = queryString.parse(location.search)
    console.log(params.email)
    setEmail(params.email)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="flex-center auth">
      <div>
        <h2 className="text-center">Enter your confirmation code</h2>
        <FormGroup>
          <Label>Email to confirm</Label>
          <Input
            type="text"
            name="email"
            value={email}
            disabled
            placeholder="Enter your email"
            onChange={(e) => handleOnChange(e, setEmail)}
          />
        </FormGroup>
        <FormGroup>
          <Label>Confirmation code</Label>
          <Input
            type="text"
            name="code"
            placeholder="Enter confirmation code"
            onChange={(e) => handleOnChange(e, setCode)}
          />
        </FormGroup>

        <button className="btn btn-primary btn-block" onClick={confirmAccount}>
          Verificate Account
        </button>
        {alert && <small>{alert}</small>}
      </div>
    </div>
  )
}

export default withRouter(ConfirmationCode)
