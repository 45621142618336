import React, { useState } from "react"
import { Link } from "react-router-dom"
// state
import { useStateValue } from "../../../state/AuthorizationState"
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
// amplify
import { Auth } from "aws-amplify"
import "./styles.scss"

const TopBar = () => {
  const { userName, isAuthenticated } = useStateValue()
  const [dropdownOpen, setDropDownOpen] = useState(false)
  const signOut = async () => {
    try {
      await Auth.signOut()
    } catch (error) {
      console.log("error signing out: ", error)
    }
  }

  return (
    <nav>
      <div className="container-fluid">
        <div className="left-side">
          <Link to="/">
            <img src="/vtn-unico.png" alt="logo" className="img-responsive logo" />
          </Link>
        </div>
        <div className="right-side">
          {!isAuthenticated && (
            <li>
              <Link to="/login">Iniciar sesión / regístrate</Link>
            </li>
          )}
          {isAuthenticated && (
            <UncontrolledDropdown
              nav
              inNavbar
              isOpen={dropdownOpen}
              onClick={() => setDropDownOpen(!dropdownOpen)}>
              <DropdownToggle nav caret>
                {userName}
              </DropdownToggle>
              <DropdownMenu right>
                <Link to="/facturas" className="dropdown-item">
                  Mi facturación
                </Link>
                <Link to="/mis-servicios-vtn" className="dropdown-item">
                  Mis servicios
                </Link>
                <Link to="/productos" className="dropdown-item">
                  Catálogo de productos
                </Link>
                <Link to="/mi-cuenta" className="dropdown-item">
                  Administrar mi cuenta
                </Link>
                <Link to="/metodos-de-pago" className="dropdown-item">
                  Administrar mis métodos de pago
                </Link>
                <DropdownItem divider />
                <DropdownItem onClick={() => signOut()}>Cerrar Sesión</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </div>
      </div>
    </nav>
  )
}

export default TopBar
