import React, { useState, useEffect } from "react"
// amplify
import { Auth } from "aws-amplify"
// utilities
import { FormGroup, Input, Label, Alert, Row, Col } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
// import { createUser } from "../../../graphql/mutations"
import "../authstyles.scss"

const CreateAccount = ({ history }) => {
  // const [username, setUsername] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confPassword, setConfPassword] = useState("")
  const [passwordIsEqual, setPasswordIsEqual] = useState(null)
  const [alert, setAlert] = useState(null)

  // verify password
  useEffect(() => {
    if (password !== confPassword) {
      setPasswordIsEqual(false)
    } else {
      setPasswordIsEqual(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confPassword])

  const handleOnChange = (e, func) => {
    const value = e.currentTarget.value
    func(value)
  }

  const createAccount = async () => {
    try {
      setAlert("Creando cuenta...")
      const auth = await Auth.signUp({
        username: email.toLowerCase(),
        password: password,
        email: email.toLowerCase(),
      })
      console.log(auth)
      setAlert("Usuario creado exitosamente")
      history.push({ pathname: "/confirm-account", search: `?email=${email.toLowerCase()}` })
    } catch (error) {
      let mensajeError = ""
      switch (error.message) {
        case "Username cannot be empty":
          mensajeError = "Favor de escribir su correo electrónico"
          break
        case "Password cannot be empty":
          mensajeError = "Favor de escribir su contraseña"
          break
        case "Incorrect username or password.":
          mensajeError = "Correo electrónico o contraseña incorrectos"
          break
        case "An account with the given email already exists.":
          mensajeError = "Ya existe una cuenta con ese correo electrónico"
          break
        default:
          mensajeError = error.message
      }
      setAlert(mensajeError)
    }
  }

  return (
    <div className="container">
      <div className="flex-center auth">
        <div>
          {/* <div className="logo-container">
          <img src="/tratoendirecto.png" className="logo" alt="logo" className="img-fluid" />
        </div> */}
          <h1 className="text-center main-title">Crea una cuenta</h1>
          <FormGroup>
            <Label>Correo electrónico</Label>
            <Input
              type="email"
              name="email"
              placeholder="Escribe tu correo electrónico"
              onChange={(e) => handleOnChange(e, setEmail)}
            />
          </FormGroup>
          <FormGroup>
            <Label>Contraseña</Label>
            <Input
              type="password"
              name="password"
              placeholder="Escribe tu contraseña"
              onChange={(e) => handleOnChange(e, setPassword)}
            />
          </FormGroup>
          <FormGroup>
            <Label>Confirmar contraseña</Label>
            <Input
              type="password"
              name="password-check"
              placeholder="Verifica tu contraseña"
              onChange={(e) => handleOnChange(e, setConfPassword)}
            />
          </FormGroup>
          <button
            className="btn btn-primary btn-block"
            disabled={!passwordIsEqual}
            onClick={createAccount}>
            Crear cuenta
          </button>
          {!passwordIsEqual && <Alert color="warning">Las contraseñas no coinciden</Alert>}
          {alert && <small>{alert}</small>}
          <br />
          <Row>
            <Col className="align-left">
              <img
                alt="fb"
                src="/iconos/fb-button.svg"
                className="hand fb-button"
                onClick={() => Auth.federatedSignIn({ provider: "Facebook" })}
              />
            </Col>
            <Col className="align-right">
              <img
                alt="google"
                src="/iconos/gog-button.svg"
                className="hand border-g"
                onClick={() => Auth.federatedSignIn({ provider: "Google" })}
              />
            </Col>
          </Row>
          <br />
          <Link to="/login" className="link-auth">
            ¿Ya tienes una cuenta? conéctate
          </Link>
        </div>
      </div>
    </div>
  )
}

export default withRouter(CreateAccount)
