/* eslint-disable */
import gql from "graphql-tag";

export const GET_PRODUCTS = gql`
  query Products {
    products(where: { active: { _eq: true } }) {
      id
      img_cover
      name
      description
    }
  }
`;
// profile
export const USER_PROFILE = gql`
  query UserProfile($id: uuid!) {
    users_by_pk(id: $id) {
      name
      email
      phone
      surname
      stripe_customer_id
    }
  }
`;

// get user stripe subscriptions
export const GET_SUBSCRIPTIONS = gql`
  query GetUserSubscriptions($userId: String!) {
    getUserStripeInformation(userId: $userId) {
      userSubscriptions
    }
  }
`;

// get user stripe subscriptions
export const GET_SUBSCRIPTION = gql`
  query GetSubscription($subscriptionId: String!) {
    getStripeSubscription(subscriptionId: $subscriptionId) {
      subscription
    }
  }
`;

// get user stripe invoices
// export const USER_INVOICES = gql`
//   query GetUserInvoices($customerId: String!) {
//     getUserStripeInvoices(customerId: $customerId) {
//       userInvoices
//     }
//   }
// `

// get associated cards
export const USER_CARDS = gql`
  query GetStripeAssociatedCards($customerId: String!) {
    getUserStripeAssociatedCards(customerId: $customerId) {
      cards
    }
  }
`;

// my apps
export const USER_APPS = gql`
  query UserApps($id: uuid!) {
    users_by_pk(id: $id) {
      email
      name
      user_apps {
        id
        metadata
        domain
        product_info {
          name
          description
          img_cover
        }
      }
      user_referred_apps {
        id
        metadata
        domain
        product_info {
          name
          description
          img_cover
        }
      }
      user_domains {
        id
        domain
        created_at
        updated_at
      }
    }
  }
`;

// my apps
export const USER_INVOICES = gql`
  query UserApps($id: uuid!) {
    users_by_pk(id: $id) {
      email
      name
      user_invoices {
        id
        amount_paid
        amount_due
        subtotal
        current_period_end
        paid
        is_on_stripe
      }
    }
  }
`;

export const DUMMIES_INVOICES = gql`
  query dummiesInvoices {
    invoices {
      amount_due
      amount_paid
      id
    }
  }
`
