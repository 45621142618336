import React, { useState, useEffect } from "react";
// components
import Factura from "@components/factura_outer"
// graphql
import { useQuery } from "@apollo/react-hooks";
import { withApollo } from "@apollo/react-hoc";
import { DUMMIES_INVOICES } from "../../graphql/queries";
// state
import { useStateValue } from "../../state/AuthorizationState";
import "./styles.scss";

const Facturas = () => {
  const { sub } = useStateValue();
  const { loading, error, data } = useQuery(DUMMIES_INVOICES);
  const [invoices, setInvoices] = useState([]);
  useEffect(() => {
    if (!loading) {
      console.log(data.invoices);
      setInvoices(data.invoices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;
  return (
    <>
      <div className="panel">
        <div className="product t-header border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-2">
                <h3>Servicio VTN</h3>
              </div>
              <div className="col-lg-2">
                <h3>Estado del pago</h3>
              </div>
              <div className="col-lg-2">
                <h3>Por pagar</h3>
              </div>
              <div className="col-lg-2">
                <h3>Inicio del periodo</h3>
              </div>
              <div className="col-lg-2">
                <h3>Fecha de corte</h3>
              </div>
            </div>
          </div>
        </div>
        {invoices.map((item, index) => {
          return <Factura key={index} props={item} />;
        })}
      </div>
    </>
  );
};

export default withApollo(Facturas);
