import React, { useState } from "react"
// amplify
import { Auth } from "aws-amplify"
// utilities
import { withRouter } from "react-router-dom"
import { FormGroup, Input, Label, Row, Col } from "reactstrap"
import { Link } from "react-router-dom"
import "../authstyles.scss"

const AuthAccount = ({ history }) => {
  const [username, setUsername] = useState("")
  // const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [alert, setAlert] = useState(null)

  const handleOnChange = (e, func) => {
    const value = e.currentTarget.value
    func(value)
  }

  const authAccount = async () => {
    try {
      setAlert("Verificando credenciales del usuario...")
      const user = await Auth.signIn(username.toLowerCase(), password)
      setAlert("Usuario verificado, redirigiendo...")
      history.push("/")
    } catch (error) {
      let mensajeError = ""
      switch (error.message) {
        case "Username cannot be empty":
          mensajeError = "Favor de escribir su correo electrónico"
          break
        case "Custom auth lambda trigger is not configured for the user pool":
          mensajeError = "Favor de escribir su contraseña"
          break
        case "Incorrect username or password.":
          mensajeError = "Correo electrónico o contraseña incorrectos"
          break
        default:
          mensajeError = "Favor de verificar su información e intentar de nuevo"
      }
      setAlert(mensajeError)
    }
  }

  return (
    <div className="container">
      <div className="auth flex-center">
        <div>
          <h1 className="text-center main-title">Conéctate a tu cuenta VTN</h1>
          <FormGroup>
            <Label>Correo electrónico</Label>
            <Input
              type="email"
              name="user"
              placeholder="Escribe tu correo electrónico"
              onChange={(e) => handleOnChange(e, setUsername)}
            />
          </FormGroup>
          <FormGroup>
            <Label>Contraseña</Label>
            <Input
              type="password"
              name="password"
              placeholder="Escribe tu contraseña"
              onChange={(e) => handleOnChange(e, setPassword)}
            />
          </FormGroup>
          <button className="btn btn-primary btn-block" onClick={authAccount}>
            Iniciar Sesión
          </button>
          {alert && <small>{alert}</small>}
          <br />
          <Row>
            <Col className="align-left">
              <img
                alt="fb"
                src="/iconos/fb-button.svg"
                className="hand fb-button"
                onClick={() => Auth.federatedSignIn({ provider: "Facebook" })}
              />
            </Col>
            <Col className="align-right">
              <img
                alt="google"
                src="/iconos/gog-button.svg"
                className="hand border-g"
                onClick={() => Auth.federatedSignIn({ provider: "Google" })}
              />
            </Col>
          </Row>
          <br />
          <Link to="/forgot-password-request-code" className="link-auth">
            ¿Olvidaste tu contraseña? <b>vamos a recuperarla</b>
          </Link>
          <Link to="/create-account" className="link-auth mt-0">
            <strong>¿No tienes una cuenta? crea una cuenta ahora</strong>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default withRouter(AuthAccount)
