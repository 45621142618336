import React, { useState } from "react"
// layout
import ProfileLayout from "../../../layouts/profile_layout"
// components
import AsociarTarjetaCredito from "../../../components/asociar_tarjeta_credito"
// helpers
import axios from "axios"
// graphql
import { withApollo } from "@apollo/react-hoc"
import { USER_CARDS } from "../../../graphql/queries"
import { useQuery } from "@apollo/react-hooks"
// state
import { useProfileStateValue } from "../../../state/ProfileState"
import "./styles.scss"

const PaymentMethods = () => {
  // para poder deshabilitar botones cuando axios está realizando una consulta ↓
  const [loadingCall, setLoadingCall] = useState(false)

  const { stripeCustomerId } = useProfileStateValue()
  const { loading, error, data } = useQuery(USER_CARDS, {
    variables: { customerId: stripeCustomerId },
  })
  if (error) return "error"
  if (loading) return "cargando"
  const cards = data.getUserStripeAssociatedCards.cards
  console.log(cards)
  const eliminaMetodoPago = async (id) => {
    // deshabilitamos botones para evitar que se llame muchas veces el endpoint
    setLoadingCall(true)
    let data = JSON.stringify({ cardId: id })
    let config = {
      method: "post",
      url: `${window.location.origin}/api/stripe/detach-card-from-customer`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    }
    try {
      const response = await axios(config)
      console.log(JSON.stringify(response.data))
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <ProfileLayout>
      <div className="panel ml--14 border-top-left-none pt-15 pb-15">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="titulo mb-6">Métodos de pago</h2>
              {cards.length === 0 ? (
                <>
                  <p className="mb-0">
                    Aún no has agregado algún método de pago, a continuación puedes agregar tu
                    tarjeta...
                  </p>
                  <AsociarTarjetaCredito stripeCustomerId={stripeCustomerId} />
                </>
              ) : (
                <>
                  <p>
                    Los cargos se deducirán de la tarjeta que se muestra debajo, este es tu pago por
                    defecto seleccionado
                    {/*This can be changed by adding a new card and making it the default using the menu on the right.*/}
                  </p>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">
                            <h3>Marca</h3>
                          </th>
                          <th scope="col">
                            <h3>Tipo</h3>
                          </th>
                          <th scope="col">
                            <h3>Ult. 4 Dígitos</h3>
                          </th>
                          <th scope="col">
                            <h3>Nombre</h3>
                          </th>
                          <th scope="col">
                            <h3>Expira</h3>
                          </th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {cards.map((cardelem, index) => {
                          const { card, billing_details } = cardelem
                          let funding = ""
                          switch (card.funding) {
                            case "credit":
                              funding = "Crédito"
                              break
                            case "debit":
                              funding = "Débito"
                              break
                            default:
                              funding = card.funding.charAt(0).toUpperCase() + card.funding.slice(1)
                          }
                          return (
                            <tr key={index}>
                              <td>{card.brand.toUpperCase()}</td>
                              <td>{funding}</td>
                              <td>{card.last4}</td>
                              <td>{billing_details.name}</td>
                              <td>
                                {card.exp_month} / {card.exp_year}
                              </td>
                              <td cope="col" className="align-right">
                                <button
                                  className="btn btn-sm btn-primary mt--5"
                                  disabled={loadingCall}
                                  onClick={() => eliminaMetodoPago(cardelem.id)}>
                                  Eliminar
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  {cards.length < 3 && (
                    <>
                      <p className="mb-0">Añade un nuevo método de pago (puedes añadir hasta 3)</p>
                      <AsociarTarjetaCredito stripeCustomerId={stripeCustomerId} />
                    </>
                  )}
                </>
              )}
            </div>
            {/* <div className="col-lg-12 col-md-12 col-sm-12">
              <hr />
              <h2 className="titulo mb-6">Recibos</h2>
              <p>A continuación verás tus pagos realizados, y podrás solicitar tu factura</p>
              <div className="row">
                <div className="col">
                  <table className="table w-100">
                    <thead>
                      <tr>
                        <th scope="col">
                          <h3>Estatus</h3>
                        </th>
                        <th scope="col">
                          <h3>Cantidad</h3>
                        </th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    {invoices.length === 0 && <p>Aún no tienes pagos registrados</p>}
                    {invoices.map((elem) => {
                      let statusPago = ""
                      switch (elem.status) {
                        case "paid":
                          statusPago = "Pagado"
                          break
                        default:
                          statusPago = elem.status.charAt(0).toUpperCase() + elem.status.slice(1)
                      }
                      return (
                        <tr>
                          <td>
                            <p>{statusPago}</p>
                          </td>
                          <td>
                            <p>{parseFloat(elem.total).toFixed(2)}</p>
                          </td>
                          <td className="align-right">
                            <p>
                              <button className="btn btn-sm btn-primary mt--5">Facturar</button>
                            </p>
                          </td>
                        </tr>
                      )
                    })}
                  </table>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </ProfileLayout>
  )
}

export default withApollo(PaymentMethods)
