import React from "react"
// components
import Product from "../../components/product"
// graphql
import { useQuery } from "@apollo/react-hooks"
import { gql } from "apollo-boost"
import { withApollo } from "@apollo/react-hoc"

export const LISTA_SUSCRIPCIONES = gql`
  {
    suscripciones(where: { usuario: { _eq: 1 } }) {
      estado
      facturacion
      cantidad
      ciclo
      conProducto {
        nombre
        divisa
        precio
      }
    }
  }
`

const Dashboard = () => {
  const { loading, error, data } = useQuery(LISTA_SUSCRIPCIONES)
  console.log(data)
  if (loading) return "Loading..."
  if (error) return `Error! ${error.message}`
  return (
    <>
      <div className="panel">
        <div className="product t-header border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-1">
                <h3>Cantidad</h3>
              </div>

              <div className="col-lg-2">
                <h3>Estado</h3>
              </div>
              <div className="col-lg-5">
                <h3>Producto</h3>
              </div>
              <div className="col-lg-2">
                <h3>Precio</h3>
              </div>
            </div>
          </div>
        </div>
        {data.suscripciones.map((item) => {
          console.log(item)
          return <Product props={item} />
        })}
      </div>
    </>
  )
}

export default withApollo(Dashboard)
