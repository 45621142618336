import React, { useState } from "react"
// amplify
import { Auth } from "aws-amplify"
// utilities
import { FormGroup, Input, Label } from "reactstrap"
import { withRouter } from "react-router-dom"
import "../authstyles.scss"

const ForgotPasswordSubmitCode = ({ history }) => {
  const [code, setCode] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [alert, setAlert] = useState(null)

  const handleOnChange = (e, func) => {
    const value = e.currentTarget.value
    func(value)
  }

  const resetPassword = async () => {
    setAlert("Reiniciando contraseña")
    try {
      await Auth.forgotPasswordSubmit(email, code, password)
      history.push("/login")
    } catch (error) {
      setAlert(error.message)
    }
  }

  return (
    <div className="flex-center auth">
      <div>
        <h2 className="text-center">Ingresa el código que enviamos a tu correo electronico</h2>
        <FormGroup>
          <Label>Correo electronico</Label>
          <Input
            type="text"
            name="email"
            placeholder="Enter your email"
            onChange={(e) => handleOnChange(e, setEmail)}
          />
        </FormGroup>
        <FormGroup>
          <Label>Codigo de confirmación</Label>
          <Input
            type="text"
            name="code"
            placeholder="Enter confirmation code"
            onChange={(e) => handleOnChange(e, setCode)}
          />
        </FormGroup>
        <FormGroup>
          <Label>Nueva contraseña</Label>
          <Input
            type="password"
            name="code"
            placeholder="Nueva contraseña"
            onChange={(e) => handleOnChange(e, setPassword)}
          />
        </FormGroup>
        <button className="btn btn-primary btn-block" onClick={() => resetPassword()}>
          Reiniciar contraseña
        </button>
        {alert && <small>{alert}</small>}
      </div>
    </div>
  )
}

export default withRouter(ForgotPasswordSubmitCode)
