import React from "react"
import LeftSidebar from "../../components/navigation/leftsidebar"
import "./styles.scss"

const DashboardLayout = ({ children, mode }) => {
  return (
    <div className="dashboard">
      <LeftSidebar mode={mode} />
      <div className="main">
        <div className="container">{children}</div>
      </div>
    </div>
  )
}

export default DashboardLayout
