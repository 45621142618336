import React from "react"
import { Nav, NavItem } from "reactstrap"
import { Link } from "react-router-dom"
import "./styles.scss"

const LeftSidebar = ({ mode }) => {
  return (
    <div className="left-sidebar">
      <Nav vertical>
        {/* <NavItem>
          <NavLink href="#" className="active">
            Mi Dashboard
          </NavLink>
        </NavItem> */}
        <NavItem>
          <Link
            className={`nav-link ${window.location.pathname === "/facturas" && "active"} `}
            to="/facturas">
            Facturas
          </Link>
        </NavItem>
        <NavItem>
          <Link
            className={`nav-link ${window.location.pathname === "/mis-servicios-vtn" && "active"} `}
            to="/mis-servicios-vtn">
            Mis servicios VTN
          </Link>
        </NavItem>
        <NavItem>
          <Link
            className={`nav-link ${window.location.pathname === "/productos" && "active"} `}
            to="/productos">
            Productos
          </Link>
        </NavItem>
        {/* <NavItem>
          <NavLink href="#">Productos</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#">Servicios</NavLink>
        </NavItem> */}
        <NavItem>
          <Link
            className={`nav-link ${window.location.pathname === "/mi-cuenta" && "active"} `}
            to="/mi-cuenta">
            Mi Cuenta
          </Link>
        </NavItem>
      </Nav>
    </div>
  )
}

export default LeftSidebar
