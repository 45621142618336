import React from "react"
import { withRouter } from "react-router-dom"
import axios from "axios"
// components
// graphql
import { withApollo } from "@apollo/react-hoc"
import { USER_APPS } from "../../graphql/queries"
import { useQuery } from "@apollo/react-hooks"
// state
import { useStateValue } from "../../state/AuthorizationState"

const Servicios = ({ history }) => {
  const { sub } = useStateValue()
  const { loading, error, data } = useQuery(USER_APPS, { variables: { id: sub } })
  if (loading) return "Loading..."
  if (error) return `Error! ${error.message}`
  console.log(data.users_by_pk)
  const { user_apps, user_referred_apps, user_domains } = data.users_by_pk
  const openCpanelAccount = (cpanel_user) => {
    let params = {
      cpanel_user: cpanel_user,
    }
    //se mandan los parámetros en formato cpanel_user=cpanel_user&type=cpanel
    const data = Object.entries(params)
      .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
      .join("&")

    let config = {
      method: "post",
      url: `https://vivetunube.com/scripts/create_user_session.php`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    }
    axios(config)
      .then((response) => {
        openInNewTab(response.data.data.url)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const openInNewTab = (href) => {
    Object.assign(document.createElement("a"), {
      target: "_blank",
      href,
    }).click()
  }
  return (
    <>
      <h3>Apps VTN</h3>
      {user_apps.map((app) => {
        return (
          <div
            className="card pd-15 mt-15"
            onClick={() => openCpanelAccount(app.metadata.cpanel_user)}>
            {/* este onclick llevará a la página del producto */}
            {/* onClick={() => history.push(`/mis-servicios-vtn/${app.id}/info-app`)} */}
            <div className="row">
              <div className="col-lg-1 col-md-12 col-sm-12">
                <img src={app.product_info.img_cover} className="img-responsive" alt="product" />
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <p>{app.product_info.name}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <p>{app.domain}</p>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                <p>{app.metadata.ip}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <p>Región: {app.metadata.region}</p>
              </div>
            </div>
          </div>
        )
      })}
      <h3 className="mt-15">Dominios</h3>
      {user_domains.map((domain) => {
        return (
          <div className="card pd-15 mt-15">
            <div className="row">
              <div className="col-md-12">
                <p>{domain.domain}</p>
              </div>
            </div>
          </div>
        )
      })}

      <h3 className="mt-15 mb--3">Apps VTN referidas y administradas por ti</h3>
      <small>
        te permite administrar fácilmente las cuentas comerciales de tus clientes, conservando el
        control que tu cliente necesita para administrar su cuenta.
      </small>
      {user_referred_apps.map((app) => {
        return (
          <div
            className="card pd-15 mt-15"
            onClick={() => openCpanelAccount(app.metadata.cpanel_user)}>
            <div className="row">
              <div className="col-lg-1 col-md-12 col-sm-12">
                <img src={app.product_info.img_cover} className="img-responsive" alt="product" />
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <p>{app.product_info.name}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <p>{app.domain}</p>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                <p>{app.metadata.ip}</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <p>Región: {app.metadata.region}</p>
              </div>
            </div>
          </div>
        )
      })}
      {/* TODO: agregar funcionalidad de dominios referidos y administradosp */}
      {/* <h3 className="mt-15 mb--3">Dominios referidos y administrados por ti</h3>
      <small>
        te permite administrar fácilmente las cuentas comerciales de tus clientes, conservando el
        control que tu cliente necesita para administrar su cuenta.
      </small> */}
    </>
  )
}

export default withApollo(withRouter(Servicios))
