import React from "react"
import { withRouter } from "react-router-dom"
// graphql
import { withApollo } from "@apollo/react-hoc"
// import { USER_APPS } from "../../graphql/queries"
import { useQuery } from "@apollo/react-hooks"
// // state
// import { useProfileStateValue } from "../../state/ProfileState"
// layouts
import FastCloudResourceLayout from "../../../layouts/fast_cloud_resource_layout"

const FastCloudResource = ({ history }) => {
  // const { loading, error, data } = useQuery(USER_APPS)
  // if (loading) return "Loading..."
  // if (error) return `Error! ${error.message}`
  // const { user_apps } = data.users_by_pk
  // console.log(user_apps)
  return (
    <FastCloudResourceLayout>
      <p>Nombre del servicio</p>
      <input value="app del señor mata" />
    </FastCloudResourceLayout>
  )
}

export default withApollo(withRouter(FastCloudResource))
