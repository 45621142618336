import React, { useEffect, useState, createContext, useContext } from "react"
import { withRouter } from "react-router-dom"
// graphql, apollo
import { UPDATE_USER_PROFILE, UPDATE_USER_EMAIL } from "../graphql/mutations"
import { USER_PROFILE } from "../graphql/queries"
import { useMutation, useQuery } from "@apollo/react-hooks"
// state
import { useStateValue } from "./AuthorizationState"
// amplify
import { Auth } from "aws-amplify"

// context
export const ProfileContext = createContext()
// main component
export const ProfileWithRouter = ({ children, history }) => {
  // id de usuario desde AuthorizationState
  const { sub } = useStateValue()
  const [name, setName] = useState("")
  const [surname, setSurname] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [newEmail, setNewEmail] = useState("")
  const [confirmNewEmail, setConfirmNewEmail] = useState("")
  const [password, setPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [alert, setAlert] = useState(null)
  const [passwordAlert, setPasswordAlert] = useState(null)
  const [emailAlert, setEmailAlert] = useState(null)
  const [stripeCustomerId, setStripeCustomerId] = useState("")

  const { loading, error, data } = useQuery(USER_PROFILE, {
    variables: {
      id: sub,
    },
  })
  useEffect(() => {
    if (!loading && data !== undefined) {
      setName(data.users_by_pk.name)
      setSurname(data.users_by_pk.surname)
      setPhone(data.users_by_pk.phone)
      setEmail(data.users_by_pk.email)
      setStripeCustomerId(data.users_by_pk.stripe_customer_id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const [update_user_profile] = useMutation(UPDATE_USER_PROFILE)
  const updateUserProfile = async () => {
    try {
      const updated = await update_user_profile({ variables: { id: sub, name, surname, phone } })
      console.log(updated)
      setAlert(
        <div className="alert alert-success">Tus datos de usuario han sido actualizados</div>
      )
    } catch (error) {
      console.log(error)
      setAlert(error.message)
    }
  }

  const [update_user_email] = useMutation(UPDATE_USER_EMAIL)
  const updateEmail = async (newEmail, confirmNewEmail) => {
    if (newEmail === confirmNewEmail) {
      try {
        // varios alerts gives the effect of points summing up while time passes by, telling the user that something is happening
        setEmailAlert(
          <div className="alert alert-info">Enviando correo de verificación al nuevo correo...</div>
        )
        const currUser = await Auth.currentAuthenticatedUser()
        setEmailAlert(
          <div className="alert alert-info">Enviando correo de verificación al nuevo correo...</div>
        )
        await Auth.updateUserAttributes(currUser, {
          email: newEmail,
        })
        setEmailAlert(
          <div className="alert alert-info">Enviando correo de verificación al nuevo correo...</div>
        )
        // update also database
        const updated = await update_user_email({ variables: { id: sub, email: newEmail } })
        console.log(updated)
        setEmailAlert(
          <div className="alert alert-success">
            El correo electrónico se actualizó correctamente
          </div>
        )
        history.push("/change-email-confirmation")
      } catch (error) {
        setEmailAlert(
          <div className="alert alert-danger">Favor de confirmar su nuevo correo electrónico</div>
        )
      }
    } else {
      setEmailAlert(<div className="alert alert-danger">Los correos no coinciden</div>)
    }
  }

  const changePassword = async (oldPassword, newPassword, confirmPassword) => {
    if (newPassword === confirmPassword) {
      try {
        const currUser = await Auth.currentAuthenticatedUser()
        await Auth.changePassword(currUser, oldPassword, newPassword)
        setPasswordAlert(
          <div className="alert alert-success">La contraseña se actualizó correctamente</div>
        )
      } catch (error) {
        switch (error.code) {
          case "InvalidParameterException":
            setPasswordAlert(
              <div className="alert alert-danger">
                La contraseña no cumple los requisitos de seguridad, asegúrate de que tenga más de 6
                caracteres
              </div>
            )
            break
          default:
            setPasswordAlert(<div className="alert alert-danger">{error.message}</div>)
            break
        }
      }
    } else {
      // las contraseñas no son iguales
      setPasswordAlert(<div className="alert alert-danger">Las contraseñas no coinciden</div>)
    }
  }

  const handleOnChange = (e, func) => {
    const value = e.currentTarget.value
    func(value)
  }
  if (error) return "error"
  if (loading) return "cargando"

  return (
    <ProfileContext.Provider
      value={{
        handleOnChange,
        name,
        setName,
        surname,
        setSurname,
        phone,
        setPhone,
        email,
        setEmail,
        setNewEmail,
        setConfirmNewEmail,
        password,
        setPassword,
        newPassword,
        setNewPassword,
        // stripe
        stripeCustomerId,
        // alerts
        alert,
        passwordAlert,
        // update functions
        updateUserProfile,
        updateEmail,
        newEmail,
        confirmNewEmail,
        emailAlert,
        changePassword,
        confirmPassword,
        setConfirmPassword,
      }}>
      {children}
    </ProfileContext.Provider>
  )
}

export const ProfileProvider = withRouter(ProfileWithRouter)

export const useProfileStateValue = () => useContext(ProfileContext)
