import React, { useEffect, useState, createContext, useContext } from "react"
import { withRouter } from "react-router-dom"
import { Auth, Hub } from "aws-amplify"
// graphql
import { useMutation } from "@apollo/react-hooks"
import { UPSERT_USER } from "../graphql/mutations"

export const AuthorizationContext = createContext()

export const AuthorizationProviderWithRouter = ({ children, history }) => {
  const [userName, setUserName] = useState(false)
  const [sub, setSub] = useState(null)
  const [idToken, setIdToken] = useState("")
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  useEffect(() => {
    listen()
    checkLoggedIn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])
  const [upsert_user] = useMutation(UPSERT_USER)
  const listen = async () => {
    Hub.listen("auth", async (data) => {
      //  payload es lo que recibimos del evento de auth
      const { payload } = data
      // si hacen signout, ponemos el estado de user como "Log In"
      if (payload.event === "signOut") {
        setIsAuthenticated(false)
        history.push("/")
        // si hacen sign in, colocamos el correo electrónico
      } else if (payload.event === "signIn") {
        // tomamos el usuario con la api de amplify Auth
        try {
          const userData = await Auth.currentAuthenticatedUser()
          console.log("userData: ")
          console.log(userData)
          const userId = userData.attributes.sub
          const email = userData.attributes.email
          const username = userData.username
          console.log("userid: " + userId)
          console.log("email: " + email)
          console.log("username: " + username)
          setIsAuthenticated(true)
          setUserName(userData.attributes.email)
          setSub(userData.attributes.sub)
          history.push("/facturas")
          await upsert_user({
            variables: {
              id: userId,
              email: email,
              username: username,
            },
          })
        } catch (error) {
          history.push("/")
          console.log(error)
        }
      }
    })
  }
  const checkLoggedIn = async () => {
    try {
      const userData = await Auth.currentAuthenticatedUser()
      setIdToken(userData.signInUserSession.idToken.jwtToken)
      setIsAuthenticated(true)
      setUserName(userData.attributes.email)
      setSub(userData.attributes.sub)
    } catch (error) {
      setIsAuthenticated(false)
      history.push("/")
      console.log(error)
    }
  }
  return (
    <AuthorizationContext.Provider
      value={{
        userName,
        sub,
        isAuthenticated,
        idToken,
        checkLoggedIn,
      }}>
      {children}
    </AuthorizationContext.Provider>
  )
}

export const AuthorizationProvider = withRouter(AuthorizationProviderWithRouter)

export const useStateValue = () => useContext(AuthorizationContext)
