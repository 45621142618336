import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// providers
import { AuthorizationProvider } from "./state/AuthorizationState";
import { ProfileProvider } from "./state/ProfileState";
// helpers
import IsAuthorized from "./helpers/authorization";
// auth
import AuthAccount from "./pages/auth/login";
import CreateAccount from "./pages/auth/createaccount";
import ConfirmationCode from "./pages/auth/confirmationcode";
import ForgotPasswordSubmitCode from "./pages/auth/forgotpassword/ForgotPaswordSubmitCode";
// layouts
import DashboardLayout from "./layouts/dashboard";
// components
import TopBar from "./components/navigation/topbar";
// pages
import Home from "./pages/home";
import Dashboard from "./pages/dashboard";
import Servicios from "./pages/mis_servicios";
import FastCloudResource from "./pages/mis_servicios/fast_cloud_resource";
import Suscripcion from "./pages/suscripcion";
import Facturas from "./pages/facturas";
import Factura from "./pages/facturas/factura_inner"
import Productos from "./pages/productos";
import ConfigPerfil from "./pages/perfil/configuracion";
import PaymentMethods from "./pages/perfil/metodos_de_pago";
// import Servicios from "./pages/servicios"

// for apollo client
import { ApolloProvider } from "@apollo/react-hooks";
import { setContext } from "apollo-link-context";
import ApolloClient from "apollo-client";
import { WebSocketLink } from "apollo-link-ws";
import { HttpLink } from "apollo-link-http";
import { split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
// amplify
import { Auth } from "aws-amplify";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";

const httpurl = `https://${process.env.REACT_APP_HASURA_URL_ENDPOINT}/v1/graphql`;
console.log(process.env.REACT_APP_HASURA_URL_ENDPOINT);
const wsurl = `wss://${process.env.REACT_APP_HASURA_URL_ENDPOINT}/v1/graphql`;
const App = () => {
  // apollo link management
  const httpLink = new HttpLink({
    uri: httpurl, // use https for secure endpoint
  });

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, extensions }) => {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
              extensions
            )}`
          );
        });
      if (networkError) {
        switch (networkError.message) {
          case "cannot start as connection_init failed with : Could not verify JWT: JWTExpired":
            Auth.currentSession()
              .then((currSession) => {
                const idToken = currSession.getIdToken();
                const jwt = idToken.getJwtToken();
                operation.setContext({
                  headers: {
                    Authorization: jwt ? `Bearer ${jwt}` : "",
                  },
                });
                // retry the request, returning the new observable
                return forward(operation);
              })
              .catch((error) => {
                console.log(error);
              });
            break;
          default:
            break;
        }
      }
    }
  );

  // Create a WebSocket link:
  const wsLink = new WebSocketLink({
    uri: wsurl, // use wss for a secure endpoint
    options: {
      lazy: true,
      reconnect: true,
      timeout: 30000,
      connectionParams: async () => {
        try {
          const currSession = await Auth.currentSession();
          let idToken = currSession.getIdToken();
          let jwt = idToken.getJwtToken();
          return {
            headers: {
              Authorization: jwt ? `Bearer ${jwt}` : "",
            },
          };
        } catch (error) {
          console.log(error);
          // si no hay usuario conectado, no mandamos los headers
        }
      },
    },
  });

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const link = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === "OperationDefinition" && operation === "subscription";
    },
    wsLink,
    httpLink
  );

  const authLink = setContext(async (_, { headers }) => {
    // return the headers to the context so httpLink can read them
    try {
      const currSession = await Auth.currentSession();
      let idToken = currSession.getIdToken();
      let jwt = idToken.getJwtToken();
      return {
        headers: {
          ...headers,
          Authorization: jwt ? `Bearer ${jwt}` : "",
        },
      };
    } catch (error) {
      console.log(error);
      // cuando no puede deolver un resultado de auth.currentSession, no necesitamos los headers
    }
  });

  // Instantiate client
  const client = new ApolloClient({
    link: errorLink.concat(authLink.concat(link)),
    cache: new InMemoryCache(),
  });
  return (
    <ApolloProvider client={client}>
      <Router>
        <AuthorizationProvider>
          <TopBar />
          <Switch>
            <Route path="/create-account">
              <CreateAccount />
            </Route>
            <Route path="/confirm-account">
              <ConfirmationCode />
            </Route>
            {/* <Route path="/forgot-password-request-code">
              <ForgotPaswordRequestCode />
            </Route> */}
            <Route path="/forgot-password-submit-code">
              <ForgotPasswordSubmitCode />
            </Route>
            <Route path="/login">
              <AuthAccount />
            </Route>
            <Route path="/dashboard">
              <IsAuthorized>
                <DashboardLayout>
                  <Dashboard />
                </DashboardLayout>
              </IsAuthorized>
            </Route>
            <Route path="/facturas/:id">
              <IsAuthorized>
                <ProfileProvider>
                  <DashboardLayout>
                    <Factura />
                  </DashboardLayout>
                </ProfileProvider>
              </IsAuthorized>
            </Route>
            <Route path="/facturas">
              <IsAuthorized>
                <ProfileProvider>
                  <DashboardLayout>
                    <Facturas />
                  </DashboardLayout>
                </ProfileProvider>
              </IsAuthorized>
            </Route>
            <Route path="/mis-servicios-vtn/:serviceId/info-app">
              {/* <IsAuthorized>
                <ProfileProvider> */}
              <DashboardLayout>
                <FastCloudResource />
              </DashboardLayout>
              {/* </ProfileProvider>
              </IsAuthorized> */}
            </Route>
            <Route path="/mis-servicios-vtn">
              {/* <IsAuthorized>
                <ProfileProvider> */}
              <DashboardLayout>
                <Servicios />
              </DashboardLayout>
              {/* </ProfileProvider>
              </IsAuthorized> */}
            </Route>
            <Route path="/suscripcion/:subscriptionId">
              <IsAuthorized>
                <ProfileProvider>
                  <DashboardLayout>
                    <Suscripcion />
                  </DashboardLayout>
                </ProfileProvider>
              </IsAuthorized>
            </Route>
            <Route path="/productos">
              <IsAuthorized>
                <DashboardLayout>
                  <Productos />
                </DashboardLayout>
              </IsAuthorized>
            </Route>
            <Route path="/mi-cuenta">
              <DashboardLayout>
                <ProfileProvider>
                  <ConfigPerfil />
                </ProfileProvider>
              </DashboardLayout>
            </Route>
            <Route path="/metodos-de-pago">
              <DashboardLayout>
                <ProfileProvider>
                  <PaymentMethods />
                </ProfileProvider>
              </DashboardLayout>
            </Route>
            {/* profile */}
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </AuthorizationProvider>
      </Router>
    </ApolloProvider>
  );
};

export default App;
