import React, { useEffect } from "react"
import { useStateValue } from "../../state/AuthorizationState"

const IsAuthorized = ({ children }) => {
  const { checkLoggedIn } = useStateValue()

  useEffect(() => {
    checkLoggedIn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>{children}</>
}

export default IsAuthorized
