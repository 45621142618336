import gql from "graphql-tag"

export const UPSERT_USER = gql`
  mutation UpsertUser($id: uuid!, $email: String!, $username: String!) {
    insert_users(
      objects: { id: $id, email: $email, username: $username }
      on_conflict: { constraint: users_pkey, update_columns: [email, username] }
    ) {
      affected_rows
    }
  }
`

// update user profile data
export const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserAttributes($id: uuid!, $name: String!, $phone: String!, $surname: String!) {
    update_users_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, surname: $surname, phone: $phone }
    ) {
      name
      phone
      surname
    }
  }
`

export const UPDATE_USER_EMAIL = gql`
  mutation UpdateUserEmail($id: uuid!, $email: String!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: { email: $email }) {
      email
    }
  }
`
