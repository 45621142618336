import React from "react"
import { withRouter } from "react-router-dom"
import { Nav, NavItem, NavLink } from "reactstrap"
// graphql
// helpers
import { Offline } from "react-detect-offline"
// utilities
// state
// styles
import "./styles.scss"

// fast cloud resource
const FastCloudResourceLayout = ({ children, history, match }) => {
  const changeRoute = (e, route) => {
    e.preventDefault()
    history.push(route)
  }
  const isActiveRoute = (route) => {
    const thePath = history.location.pathname
    const lastItem = thePath.substring(thePath.lastIndexOf("/") + 1)
    return lastItem === route ? true : false
  }
  return (
    <div className="propiedades-search-page">
      <div className="container mt-15">
        <div className="row">
          <Nav tabs>
            <NavItem>
              <NavLink
                href="#"
                onClick={(e) =>
                  changeRoute(e, `/mis-servicios-vtn/${match.params.serviceId}/info-app`)
                }
                active={isActiveRoute("info-app")}>
                Información App
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                onClick={(e) =>
                  changeRoute(e, `/mis-servicios-vtn/${match.params.serviceId}/info-suscription`)
                }
                active={isActiveRoute("info-suscription")}>
                Información Suscripción
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <OfflineMessage />
        {children}
      </div>
    </div>
  )
}

const OfflineMessage = () => {
  return (
    <Offline>
      <div className="container">
        <div className="alert alert-warning" role="alert">
          Tu conexión a internet es inestable, vtn no funcionará como es esperado, revisa tu
          conexión a internet.
        </div>
      </div>
    </Offline>
  )
}
export default withRouter(FastCloudResourceLayout)
