import React from "react";
import Lottie from "react-lottie";
import * as animationData from "./vtn_embudo.json";
import "./styles.scss";
const Home = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <section className="portada h-screen">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="center-element">
              <div className="centered">
                <h1>
                  ADMINISTRA
                  <br /> TODOS TUS RECURSOS EN LA NUBE <br />
                </h1>
                {/* <p>
                  Obtain NGINX Powered sites, running on a docker machine with automatic backups over
                  a SSD drive.
                </p>
                <p>In less than 1 minute.</p>
                <p>
                  switch to a fast server optimized for enterprise e-commerce. Join thousands of other
                  sites improving their bounce rates.
                </p> */}
                <button className="btn btn-secondary ">
                  Crear un recurso VTN en la nube{" "}
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="center-element">
              <div className="centered">
                <Lottie
                  options={defaultOptions}
                  height={444}
                  width={444}
                  isStopped={false}
                  isPaused={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
