import React from "react"
// graphql
import { useQuery } from "@apollo/react-hooks"
import { withApollo } from "@apollo/react-hoc"
import { USER_INVOICES } from "../../../graphql/queries"

const Factura = () => {
  return (
    <header className="invoice-head">
      <small>Recibo para</small>
      <h1>Israel Ortiz</h1>
      <h2>Inca pte #123 col. Azteca. Gpe. N.L.</h2>
    </header>
  )
}

export default withApollo(Factura)
