/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from "react"
import { Container, Row, Col, FormGroup, Input } from "reactstrap"

// helpers
import axios from "axios"
// stripe
import { loadStripe } from "@stripe/stripe-js"
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js"
import "./styles.scss"
const CheckoutForm = ({ stripeCustomerId }) => {
  const [paymentStatus, setPaymentStatus] = useState({
    paying: false,
    msg: "",
    btntxt: "Añadir tarjeta",
    alert: false,
  })
  const [name, setName] = useState("")

  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }
    if (name === "") {
      setPaymentStatus({
        paying: false,
        msg: "Falta el nombre del titular de la tarjeta",
        btntxt: "Pagar ahora",
        alert: true,
      })
      return
    }
    setPaymentStatus({
      paying: true,
      msg: "",
      btntxt: "Asociando tarjeta",
      alert: true,
    })
    // paying a true para mostrar que se está trabajando en el pago en el cliente
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        name: name,
      },
    })

    if (!error) {
      console.log(paymentMethod)
      const paymentMethodId = paymentMethod.id
      console.log(paymentMethodId)
      try {
        const response = await axios.post("/api/stripe/attach-card-to-customer", {
          customerId: stripeCustomerId,
          paymentMethodId: paymentMethodId,
        })
        console.log(response)
        window.location.reload()
        // start confirm card payment
        // retrieve client secret
        // const { client_secret, status, charges } = response.data.confirm
        // const { receipt_url } = charges.data[0]
        // manejo de 3d secure, dentro de esta función se corre la confirmación del pago
        // confirm3dSecure(client_secret, status, receipt_url)
      } catch (error) {
        if (error.response.status === 500) {
          console.log("error 500")
        } else {
          console.log(error)
          console.log(error.response.data.message)
        }
      }
    } else {
      // errores locales
      console.log(error)
      console.log(error.message)
    }
  }
  //   const confirm3dSecure = async (client_secret, status, receipt_url) => {
  //     // confirm with 3d secure
  //     if (status === "requires_action") {
  //       const result = await stripe.confirmCardPayment(client_secret, {
  //         payment_method: {
  //           card: elements.getElement(CardElement),
  //           billing_details: {
  //             name: name,
  //           },
  //         },
  //       })
  //       if (result.error) {
  //         // Show error to your customer (e.g., insufficient funds)
  //         setPaymentStatus({
  //           paying: false,
  //           btntxt: "Pagar ahora",
  //           msg: result.error.message,
  //           alert: true,
  //         })
  //       } else {
  //         // The payment has been processed!
  //         if (result.paymentIntent.status === "succeeded") {
  //           // se procesa el pago si hubo una verificación de parte del banco
  //           setPaymentStatus({
  //             paying: true,
  //             btntxt: "Pago realizado con éxito",
  //             msg: "",
  //             alert: false,
  //           })
  //           // Show a success message to your customer
  //           // There's a risk of the customer closing the window before callback
  //           // execution. Set up a webhook or plugin to listen for the
  //           // payment_intent.succeeded event that handles any business critical
  //           // post-payment actions.
  //         }
  //       }
  //       console.log(result)
  //     } else {
  //       // si no hubo verificación de parte del banco, nos pasamos a ejecutar de este lado
  //       setPaymentStatus({
  //         paying: true,
  //         btntxt: "Pago realizado con éxito",
  //         msg: "",
  //         alert: false,
  //       })
  //     }
  //     // confirm with 3d secure
  //   }
  const handleOnChange = (e) => {
    const value = e.currentTarget.value
    setName(value)
  }
  return (
    <form>
      <br />
      <FormGroup>
        <Input
          type="text"
          name="nombretitular"
          placeholder="Nombre del titular"
          onChange={(e) => handleOnChange(e)}
        />
      </FormGroup>
      <CardElement />
      <hr />
      {paymentStatus.paying ? (
        <button className="btn btn-primary btn-block" disabled>
          {paymentStatus.btntxt}
        </button>
      ) : (
        <button
          className="btn btn-primary btn-block"
          disabled={paymentStatus.paying}
          onClick={(e) => handleSubmit(e)}>
          {paymentStatus.btntxt}
        </button>
      )}
      {/* <Alert
                color="warning"
                isOpen={paymentStatus.alert}
                toggle={() =>
                    setPaymentStatus({ paying: false, msg: "", btntxt: "Pagar ahora", alert: false })
                }>
                {paymentStatus.msg}
            </Alert> */}
    </form>
  )
}
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)
const AsociarTarjetaCredito = ({ stripeCustomerId }) => {
  return (
    <Container>
      <Row>
        <Col>
          <Elements stripe={stripePromise}>
            <CheckoutForm stripeCustomerId={stripeCustomerId} />
          </Elements>
        </Col>
      </Row>
    </Container>
  )
}

export default AsociarTarjetaCredito
