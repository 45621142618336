import React from "react"
import "./styles.scss"
const Product = ({
  props: {
    status,
    quantity,
    plan: { amount, nickname, currency, interval },
  },
}) => {
  return (
    <>
      <div className="t-body product border-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-1">
              <p>{quantity}</p>
            </div>
            <div className="col-lg-2">
              <p>{status}</p>
            </div>
            <div className="col-lg-5">
              <p>{nickname}</p>
            </div>
            <div className="col-lg-2">
              <p>
                ${(amount / 100).toFixed(2)}
                {currency} / {interval}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Product
