// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id: "us-east-1:aae94def-ccc6-4ea3-9daf-c736c1f089dd",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_CA15AysjW",
  aws_user_pools_web_client_id: "1ms2nmupe1hrju8sfoepgv7ho6",
  oauth: {
    domain: "vtn-users-dev.auth.us-east-1.amazoncognito.com",
    scope: ["phone", "email", "openid", "profile", "aws.cognito.signin.user.admin"],
    redirectSignIn: "https://mi.vivetunube.com/",
    redirectSignOut: "https://mi.vivetunube.com/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
}

export default awsmobile
