import React, { useState } from "react"
// utilities
import { FormGroup, Input, Label } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye } from "@fortawesome/free-solid-svg-icons"
// layout
import ProfileLayout from "../../../layouts/profile_layout"
// state
import { useProfileStateValue } from "../../../state/ProfileState"

const ConfigPerfil = () => {
  return (
    <ProfileLayout>
      <div className="panel ml--14 border-top-left-none pt-15 pb-15">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <DatosPersonales />
            </div>
            <div className="col-lg-4">
              <ActualizarCorreo />
            </div>
            <div className="col-lg-4">
              <ActualizarContrasena />
            </div>
          </div>
        </div>
      </div>
    </ProfileLayout>
  )
}

const DatosPersonales = () => {
  const {
    handleOnChange,
    name,
    setName,
    surname,
    setSurname,
    phone,
    setPhone,
    updateUserProfile,
    alert,
  } = useProfileStateValue()
  return (
    <div className="row">
      <div className="col-lg-12">
        <FormGroup>
          <Label>Nombre</Label>
          <Input
            type="text"
            name="name"
            placeholder="Tu nombre"
            value={name}
            onChange={(e) => handleOnChange(e, setName)}
          />
        </FormGroup>
      </div>
      <div className="col-lg-12">
        <FormGroup>
          <Label>Apellidos</Label>
          <Input
            type="text"
            name="surname"
            placeholder="Tus apellidos"
            value={surname}
            onChange={(e) => handleOnChange(e, setSurname)}
          />
        </FormGroup>
      </div>
      <div className="col-lg-12">
        <FormGroup>
          <Label>Teléfono</Label>
          <Input
            type="text"
            name="name"
            placeholder="+52"
            value={phone}
            onChange={(e) => handleOnChange(e, setPhone)}
          />
        </FormGroup>
      </div>
      <div className="col-lg-12 mb-30">
        <button className="btn btn-primary btn-block " onClick={() => updateUserProfile()}>
          Actualizar datos de contacto
        </button>
        {alert && <small>{alert}</small>}
      </div>
    </div>
  )
}

const ActualizarCorreo = () => {
  const {
    handleOnChange,
    email,
    setEmail,
    updateEmail,
    newEmail,
    confirmNewEmail,
    setNewEmail,
    setConfirmNewEmail,
    emailAlert,
  } = useProfileStateValue()
  return (
    <div className="row">
      <div className="col-lg-12">
        <FormGroup>
          <Label>Correo electrónico actual</Label>
          <Input
            type="email"
            name="email"
            placeholder="tu@email.com"
            value={email}
            onChange={(e) => handleOnChange(e, setEmail)}
            disabled={true}
          />
        </FormGroup>
      </div>
      <div className="col-lg-12">
        <FormGroup>
          <Label>Nuevo correo electrónico</Label>
          <Input
            type="email"
            name="email"
            placeholder="tu@email.com"
            value={newEmail}
            onChange={(e) => handleOnChange(e, setNewEmail)}
          />
        </FormGroup>
      </div>
      <div className="col-lg-12">
        <FormGroup>
          <Label>Confirmar nuevo correo electrónico</Label>
          <Input
            type="email"
            name="email"
            placeholder="tu@email.com"
            value={confirmNewEmail}
            onChange={(e) => handleOnChange(e, setConfirmNewEmail)}
          />
        </FormGroup>
      </div>
      <div className="col-lg-12">
        <button
          className="btn btn-primary btn-block mb-30"
          onClick={() => updateEmail(newEmail, confirmNewEmail)}>
          Cambiar correo electrónico
        </button>
        {emailAlert && emailAlert}
      </div>
    </div>
  )
}

const ActualizarContrasena = () => {
  const {
    handleOnChange,
    password,
    setPassword,
    newPassword,
    setNewPassword,
    changePassword,
    confirmPassword,
    setConfirmPassword,
    passwordAlert,
  } = useProfileStateValue()

  const [showPassword, setShowPassword] = useState(false)
  return (
    <div className="row">
      <div className="col-lg-12">
        <FormGroup>
          <Label>Contraseña antigua</Label>
          <Input
            type={showPassword ? "text" : "password"}
            name="text"
            placeholder=""
            value={password}
            onChange={(e) => handleOnChange(e, setPassword)}
          />
        </FormGroup>
      </div>
      <div className="col-lg-12">
        <FormGroup>
          <Label>Nueva contraseña</Label>
          <Input
            type={showPassword ? "text" : "password"}
            name="text"
            placeholder=""
            value={newPassword}
            onChange={(e) => handleOnChange(e, setNewPassword)}
          />
        </FormGroup>
      </div>
      <div className="col-lg-12">
        <FormGroup>
          <Label>Confirmar nueva contraseña</Label>
          <Input
            type={showPassword ? "text" : "password"}
            name="text"
            placeholder=""
            value={confirmPassword}
            onChange={(e) => handleOnChange(e, setConfirmPassword)}
          />
        </FormGroup>
      </div>
      <div className="col-lg-12">
        <button
          className="btn btn-primary btn-block mb-10"
          onClick={() => changePassword(password, newPassword, confirmPassword)}>
          Actualizar contraseña
        </button>
        <span className="cursor" onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? (
            <b>Ocultar contraseña al escribir </b>
          ) : (
            <b>Mostrar contraseña al escribir </b>
          )}

          <FontAwesomeIcon icon={faEye} />
        </span>
        {passwordAlert && passwordAlert}
      </div>
    </div>
  )
}

export default ConfigPerfil
