import React from "react"
// graphql
import { useQuery } from "@apollo/react-hooks"
import { withApollo } from "@apollo/react-hoc"
import { GET_PRODUCTS } from "../../graphql/queries"
// state
import "./styles.scss"

const Productos = () => {
  const { loading, error, data } = useQuery(GET_PRODUCTS)
  console.log(data)
  if (loading) return "Loading..."
  if (error) return `Error! ${error.message}`
  return (
    <div className="row">
      {data.products.map((item) => {
        return (
          <div className="col-lg-4">
            <div className="panel pd-15 mt-15">
              <img className="img-fluid img_cover" src={item.img_cover} alt={item.name} />
              <h2>{item.name}</h2>
              <p>{item.description}</p>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default withApollo(Productos)
